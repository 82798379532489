.containerBox {
  position: relative;
  display: inline-block;
}

.textBox {
  position: absolute;
  height: 100%;
  text-align: center;
  width: 100%;
}

.textBox:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.containerBox h3 {
  display: inline-block;
  font-size: 20px;
  color: #fff;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}