h3 {
    text-shadow: none;
    color: #222;
}

.recipe-cont ul {
    list-style-type: disc;
    list-style-position: inside;
    list-style: inside;
    padding-left: 20px;
    margin: 20px 0px 20px 0px;
}

.recipe-cont ol {
    list-style: decimal;
    list-style-position: inside;
    padding-left: 20px;
    margin: 20px 0px 20px 0px;
}

p {
    margin-top: 15px;
}