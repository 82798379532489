.img-suggestion {
    /* max-width: 90px; */
    padding: 5px;
    overflow: hidden;
    text-align: center;
}

.suggestions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.card-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}